<template>
    <div class="modal-send-contact" @click="refresh">
        <div class="head flex-row flex-between items-start">
            <div class="title" v-html="`만남 피드백 보내기`" />
            <i class="material-icons" @click.stop="$emit('close')">close</i>
        </div>
        <div class="body">
            <p class="m-b-16">만남 피드백을 진행할 상대를 골라주세요.</p>
            <div class="items">
                <div
                    class="item"
                    :class="{ selected: item.selected, line: sentFeedback(item.schedule.schedule_status) }"
                    v-for="item in items"
                    :key="item.id"
                    @click.stop="onClickItem(item)"
                >
                    <span>{{ item.name }}</span>
                    <span class="border">ㅣ</span>
                    <span>{{ $options.filters.asAge(item.birthday) }}</span>
                    <span class="border">ㅣ</span>
                    <span>{{ item.job }}</span>
                </div>
            </div>
            <div v-if="!items.length" class="items-empty">보낼 피드백이 없습니다.</div>
        </div>
        <div class="buttons bottom">
            <div class="flex-row">
                <button @click="$emit('close', 0)" class="btn-default">
                    {{ 'CANCEL' | translate }}
                </button>
                <button @click.stop="onClickConfirm" ref="send" class="btn-primary" :class="{ disabled }">
                    {{ 'CONFIRM' | translate }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import scheduleService from '@/services/schedule'

export default {
    name: 'ModalSendFeedback',
    props: ['options'],
    data: () => ({
        items: [],
        selected: null,
    }),
    computed: {
        disabled() {
            if (!this.items.length) return true

            return !this.items.some(i => i.selected)
        },
        chat() {
            return this.options.chat
        },
        gender() {
            return this.chat.user.gender === 0 ? 'b' : 'a'
        },
        sentFeedback() {
            return status => {
                return (
                    status === 'send_feedback' ||
                    status.includes('complete_feedback') ||
                    status === `send_feedback_${this.gender}`
                )
            }
        },
    },
    methods: {
        onClickItem(item) {
            if (this.sentFeedback(item.schedule.schedule_status)) {
                return
            }

            this.items = this.items.map(i => ({
                ...i,
                selected: i.schedule.id === item.schedule.id,
            }))
            this.selected = this.items.find(i => i.selected)
        },
        async onClickConfirm() {
            if (!this.selected) return
            try {
                const res = await scheduleService.createSchedule({
                    a_user_id: this.selected.schedule.a_user_id,
                    b_user_id: this.selected.schedule.b_user_id,
                    chat_id: this.selected.schedule[`${this.gender}_chat_id`],
                })
                // console.log(res, 'res')
                await this.$store.dispatch('loadFeedbackTarget')
                this.$emit('close')
            } catch (e) {
                this.$toast.error(e.data.msg)
            }
        },
        refresh() {
            this.items = this.items.map(i => ({
                ...i,
                selected: false,
            }))
            this.selected = null
        },
    },
    async mounted() {
        await this.$store.dispatch('loadFeedbackTarget')
        const data = this.chat.$$feedbackRequests
        this.items = data.map(d => ({
            ...d,
            selected: false,
        }))
    },
}
</script>

<style scoped lang="scss">
.title {
    margin-bottom: 0;
}
.border {
    margin: 0 8px;
    color: $grey-04;
    @include f-regular;
}
.item {
    position: relative;
    width: 100%;
    padding: 8px 16px;
    color: black;
    margin-bottom: 12px;
    border: 1px solid $grey-02;
    border-radius: 4px;
    @include f-bold;

    &.inactive {
        background: $grey-03;
    }
    &.selected {
        background: $purple-primary;
        color: white;
    }
    &.line {
        background: white;
        color: black;
    }
    &.line::after {
        content: '';
        background: black;
        width: 100%;
        height: 1px;
        position: absolute;
        top: 50%;
        left: 0;
    }
}
.items {
    position: relative;
}
.items-empty {
    width: 100%;
    height: 86px;
    color: $grey-04;
    font-size: 14px;
    @include center;
}
</style>
